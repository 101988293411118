<!--授卡管理-->
<!--<div class='AwardCard'></div>-->
<template>
    <div class="AwardCard" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select placeholder="请选择" v-model="form.deptGroupCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="bd in meta.deptGroup"
                                    :label="bd.name"
                                    :value="bd.code"
                                    :key="bd.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="单据号" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡类型">
                            <el-select placeholder="会员卡类型" v-model="form.cardType">
                                <el-option label="请选择" value="" />
                                <el-option v-for="cd in meta.cards" :label="cd.name" :value="cd.flag" :key="cd.flag" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                type="date"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.member.awardCard.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.member.awardCard.create')"
                >新建</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.member.awardCard.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="470"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />

                <el-table-column label="单据号" width="170" prop="code" v-if="showColumn('code')" />
                <el-table-column label="会员卡类型" width="100" prop="cardTypeName" v-if="showColumn('cardTypeName')" />
                <el-table-column label="待发数量" width="100" prop="forAwardCount" v-if="showColumn('forAwardCount')" />
                <el-table-column label="全部数量" width="100" prop="totalCount" v-if="showColumn('totalCount')" />
                <el-table-column
                    label="机构组名称"
                    width="200"
                    prop="deptGroupName"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column label="创建人" width="150" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建日期" width="150" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="操作"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                    width="240"
                    header-align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.member.awardCard.open')"
                            >发卡信息</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.member.awardCard.edit')"
                            >编辑</el-button
                        >
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.member.awardCard.delete')"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'AwardCard',
    components: { CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                search: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            meta: {
                deptGroup: [],
                cards: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/member/awardCard/page',
                delete: '/member/awardCard/delete',
            },
        };
    },
    mounted() {
        this.inits();
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        inits() {
            const _this = this;
            UrlUtils.GoodsCardType(_this, (data) => {
                _this.meta.cards = data;
            });
            UrlUtils.DeptGroup(_this, (rst) => {
                _this.meta.deptGroup = rst || [];
            });
        },
        handleDelete(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.member.awardCard.create', ['会员管理', '授卡管理', '新建授卡']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.member.awardCard.detail', ['会员管理', '授卡管理', '授卡管理详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.member.awardCard.edit', ['会员管理', '授卡管理', '编辑授卡管理'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '授卡', '/member/awardCard/export', this.form, codes);
        },
    },
};
</script>

<style scoped>
.AwardCard .el-form-item {
    margin-bottom: 0;
}
</style>
